import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2560.000000 2560.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,2560.000000) scale(0.100000,-0.100000)">
					<path d="M10836 19569 c-112 -20 -131 -27 -246 -81 -78 -37 -132 -78 -227
-169 -82 -79 -185 -247 -199 -326 -4 -21 -11 -43 -16 -50 -6 -8 -1 -17 17 -29
24 -16 100 -17 1093 -13 l1067 4 40 -22 c50 -26 69 -47 92 -98 16 -37 17 -143
17 -1571 1 -1282 3 -1534 15 -1547 12 -16 88 -17 901 -17 893 0 1049 5 1134
36 22 9 59 20 81 25 22 6 55 19 73 30 18 10 37 19 41 19 5 0 55 28 112 63 175
105 360 308 439 482 98 215 131 454 95 679 -47 292 -220 577 -450 743 -63 45
-82 82 -61 122 107 201 156 384 156 590 0 138 -30 308 -67 381 -7 14 -13 30
-13 37 0 7 -10 32 -22 55 -85 170 -193 307 -327 416 -52 42 -185 132 -196 132
-3 0 -24 9 -48 19 -211 96 -256 102 -777 108 -234 2 -439 1 -457 -2 -30 -5
-32 -7 -22 -33 6 -15 20 -36 32 -47 29 -27 117 -131 117 -140 0 -3 11 -21 24
-38 49 -67 156 -328 156 -380 0 -32 37 -37 268 -37 219 0 232 -1 311 -26 162
-51 268 -155 323 -315 51 -150 13 -319 -104 -457 -32 -38 -150 -118 -197 -134
-24 -8 -166 -13 -423 -15 -372 -4 -387 -4 -406 15 -18 18 -20 41 -25 408 -3
214 -10 420 -16 458 -13 80 -64 231 -86 256 -8 9 -15 21 -15 26 0 6 -19 39
-42 75 -70 109 -195 221 -313 282 -196 101 -232 104 -1090 105 -603 0 -658 -1
-759 -19z m3506 -2308 c86 -21 146 -53 205 -111 132 -126 185 -321 131 -481
-42 -125 -113 -210 -238 -282 -92 -53 -143 -57 -720 -57 l-529 0 -15 28 c-12
23 -15 104 -17 449 -2 382 -1 424 15 447 l16 26 539 0 c492 0 545 -2 613 -19z"/>
					<path d="M11347 18778 c-9 -5 -13 -280 -15 -1115 -3 -1197 -2 -1171 -57 -1287
-48 -102 -169 -191 -295 -217 -90 -19 -280 -7 -375 24 -116 38 -247 120 -375
236 -25 22 -50 41 -56 41 -6 0 -123 -110 -260 -244 l-249 -244 40 -42 c137
-142 393 -300 602 -370 165 -55 300 -73 548 -74 272 -1 430 24 630 99 333 125
564 396 647 761 l22 99 3 1168 c2 642 0 1168 -4 1168 -36 6 -797 3 -806 -3z"/>
					<path d="M9160 14913 c0 -5 -1 -305 -2 -668 -3 -759 2 -715 -88 -715 -78 0
-88 18 -92 160 l-3 85 -162 3 -163 2 0 -119 c0 -66 5 -144 11 -173 36 -172
147 -269 328 -286 223 -22 374 39 446 178 44 84 44 102 45 835 l1 700 -160 3
c-89 1 -161 -1 -161 -5z"/>
					<path d="M9802 14058 l3 -863 180 -3 c320 -5 452 20 537 103 79 75 106 162
114 365 8 181 -6 339 -36 408 l-22 51 25 63 c43 110 45 366 2 505 -34 113
-141 199 -276 222 -36 6 -170 11 -297 11 l-232 0 2 -862z m473 510 c20 -18 26
-36 31 -84 8 -80 -3 -151 -27 -175 -15 -15 -33 -19 -84 -19 l-65 0 0 150 0
150 59 0 c48 0 65 -5 86 -22z m-16 -623 c16 -8 32 -22 36 -32 14 -32 18 -282
6 -324 -14 -47 -29 -56 -106 -60 l-60 -4 -3 205 c-1 112 0 210 2 217 7 18 90
17 125 -2z"/>
					<path d="M11040 14060 l0 -860 22 -5 c13 -3 86 -4 163 -3 l140 3 3 863 2 862
-165 0 -164 0 -1 -860z"/>
					<path d="M11688 14068 c1 -469 4 -859 7 -866 3 -10 91 -12 412 -10 l408 3 0
165 0 165 -252 3 -253 2 0 695 0 695 -162 0 -163 0 3 -852z"/>
					<path d="M12810 14060 l0 -860 23 -5 c12 -3 200 -4 417 -3 l395 3 0 165 0 165
-252 3 -253 2 0 695 0 695 -165 0 -165 0 0 -860z"/>
					<path d="M14160 14909 c-74 -13 -147 -49 -193 -95 -78 -79 -107 -174 -107
-353 l0 -121 165 0 165 0 0 100 c0 94 2 103 25 125 33 34 93 34 123 2 29 -31
29 -107 0 -184 -26 -72 -112 -160 -213 -220 -119 -71 -184 -150 -224 -269 -34
-104 -42 -187 -39 -449 l3 -250 410 0 410 0 0 165 0 165 -248 0 -247 0 0 72
c0 146 60 236 236 354 188 126 250 243 261 495 8 184 -17 280 -94 362 -88 92
-252 131 -433 101z"/>
					<path d="M14902 14408 l3 -513 252 -3 253 -2 2 -348 3 -347 160 0 160 0 3 863
2 862 -165 0 -165 0 0 -350 0 -350 -90 0 -90 0 0 350 0 350 -165 0 -165 0 2
-512z"/>
					<path d="M15950 14755 l0 -165 231 0 231 0 -6 -27 c-4 -16 -88 -326 -187 -691
-99 -365 -177 -667 -174 -672 7 -12 325 -13 340 -2 6 4 41 120 79 257 37 138
94 349 127 470 33 121 76 279 96 350 19 72 47 168 61 215 22 74 26 108 30 258
l4 172 -416 0 -416 0 0 -165z"/>
					<path d="M12039 12567 c-46 -35 -43 -91 6 -123 33 -21 69 -11 98 28 18 24 18
30 7 58 -20 47 -74 66 -111 37z"/>
					<path d="M12378 12551 c-85 -8 -85 -8 -90 -35 -2 -16 7 -24 42 -37 l45 -17 3
-325 2 -326 -47 -13 c-37 -10 -49 -19 -51 -36 -3 -22 -3 -22 152 -22 150 0
156 1 156 20 0 21 -21 33 -72 44 l-28 6 0 363 c0 350 -3 393 -29 385 -4 0 -41
-4 -83 -7z"/>
					<path d="M12743 12551 c-78 -7 -80 -7 -85 -35 -2 -16 7 -23 42 -36 l45 -16 0
-328 0 -329 -45 -11 c-35 -9 -46 -16 -48 -33 l-3 -23 155 0 156 0 0 25 c0 14
-6 25 -12 25 -7 0 -30 5 -50 11 l-38 10 0 362 c0 364 -2 390 -34 385 -6 -1
-43 -4 -83 -7z"/>
					<path d="M11060 12030 l0 -520 27 -11 c55 -21 53 -42 53 520 0 566 4 531 -56
531 l-24 0 0 -520z"/>
					<path d="M11340 12541 c-60 -6 -65 -8 -65 -30 0 -20 8 -27 43 -38 l42 -15 0
-344 0 -345 47 -21 c64 -28 178 -29 252 -3 141 52 219 196 192 358 -20 118
-69 185 -152 207 -63 17 -142 -8 -201 -64 -15 -14 -29 -26 -31 -26 -2 0 -1 63
1 139 3 77 2 151 -2 165 -7 28 -3 28 -126 17z m330 -330 c49 -34 65 -85 65
-206 0 -127 -15 -167 -75 -198 -41 -21 -110 -18 -159 8 l-31 16 0 174 0 174
28 19 c59 43 123 48 172 13z"/>
					<path d="M13580 12027 l0 -525 26 -6 c15 -4 31 -3 36 2 4 4 7 242 6 528 l-3
519 -32 3 -33 3 0 -524z"/>
					<path d="M14174 12543 c-31 -3 -43 -9 -47 -23 -6 -23 6 -33 54 -49 l36 -12 6
-77 c2 -42 3 -80 0 -85 -2 -4 -14 -2 -26 5 -12 6 -49 12 -82 12 -108 0 -199
-66 -254 -184 -37 -79 -37 -201 1 -281 37 -81 82 -112 168 -117 75 -5 107 7
157 58 l31 31 18 -38 c25 -51 60 -67 127 -56 46 7 52 11 55 34 3 23 -1 27 -32
33 -66 13 -66 11 -66 403 l0 353 -52 -2 c-29 -1 -72 -3 -94 -5z m-37 -304 c78
-38 83 -50 83 -224 0 -164 0 -164 -59 -195 -36 -19 -112 -17 -143 4 -57 37
-90 168 -69 279 14 78 29 107 70 134 42 28 63 29 118 2z"/>
					<path d="M9953 12355 c-85 -86 -96 -104 -65 -106 63 -4 57 17 62 -219 5 -217
9 -240 47 -278 32 -32 124 -37 178 -10 34 18 75 61 75 80 0 21 -12 23 -42 7
-12 -6 -39 -11 -62 -11 -31 1 -46 7 -63 27 -22 25 -23 34 -23 211 l0 184 90 0
90 0 0 30 0 30 -90 0 -90 0 0 70 c0 49 -4 70 -12 70 -7 0 -50 -38 -95 -85z"/>
					<path d="M15086 12354 c-43 -45 -76 -88 -74 -96 3 -7 19 -13 39 -13 l34 0 5
-220 c5 -209 6 -222 28 -252 49 -69 169 -64 250 10 21 19 31 57 15 57 -5 0
-26 -7 -48 -14 -47 -17 -85 -11 -114 15 -20 19 -21 30 -21 209 l0 189 88 3
c87 3 87 3 90 31 l3 27 -90 0 -91 0 0 70 c0 93 -13 91 -114 -16z"/>
					<path d="M13155 12306 c-83 -31 -137 -126 -116 -204 14 -51 59 -95 153 -149
113 -65 142 -109 98 -153 -14 -14 -33 -20 -63 -20 -50 0 -85 29 -112 93 -18
43 -32 46 -69 12 -52 -48 -31 -108 53 -150 52 -26 174 -18 223 15 44 30 78 86
78 129 0 66 -39 110 -160 182 -38 22 -80 52 -94 66 -50 50 -23 136 43 136 43
0 77 -35 91 -94 14 -57 29 -62 71 -20 48 48 34 115 -29 145 -50 24 -119 29
-167 12z"/>
					<path d="M14642 12301 c-47 -16 -92 -46 -114 -76 -17 -22 -8 -74 14 -89 41
-29 54 -21 67 42 14 74 39 92 100 74 32 -10 43 -20 60 -57 12 -26 21 -65 21
-94 0 -52 -10 -61 -67 -61 -38 0 -133 -41 -168 -71 -48 -43 -71 -108 -56 -159
31 -104 174 -119 262 -28 l37 38 7 -27 c11 -44 41 -63 101 -63 68 0 84 7 84
36 0 19 -8 25 -40 34 -21 6 -43 16 -47 23 -4 6 -10 100 -13 208 -5 195 -5 196
-33 232 -44 58 -119 71 -215 38z m148 -370 l0 -78 -40 -27 c-70 -46 -132 -29
-145 40 -9 44 25 90 88 119 28 14 62 25 75 25 21 0 22 -4 22 -79z"/>
					<path d="M15585 12300 c-78 -25 -125 -69 -125 -115 0 -60 73 -91 84 -35 3 17
8 43 11 59 6 27 38 51 70 51 57 0 105 -72 105 -156 l0 -50 -62 -13 c-169 -33
-261 -130 -226 -237 33 -100 172 -111 259 -22 37 38 49 40 49 7 0 -10 10 -28
22 -39 19 -17 33 -20 88 -18 65 3 65 3 65 33 0 25 -5 30 -33 36 -19 4 -39 12
-45 18 -8 8 -14 79 -17 209 -5 213 -10 232 -64 267 -38 25 -113 28 -181 5z
m145 -369 c0 -77 -1 -80 -30 -101 -46 -33 -103 -37 -129 -11 -53 53 -29 119
58 164 30 15 65 27 78 27 22 0 23 -3 23 -79z"/>
					<path d="M12050 12303 c-93 -7 -110 -12 -110 -36 0 -18 10 -26 43 -37 l42 -15
0 -204 0 -204 -45 -11 c-35 -9 -46 -16 -48 -33 l-3 -23 155 0 156 0 0 24 c0
20 -7 26 -32 31 -74 15 -68 -9 -68 254 0 235 -4 264 -34 259 -6 -1 -31 -3 -56
-5z"/>
					<path d="M9277 12279 c-5 -17 1 -24 33 -36 44 -18 43 -15 152 -333 33 -96 64
-179 68 -184 5 -5 25 -5 46 0 36 9 38 12 66 94 15 47 54 157 86 245 55 155 59
161 95 178 25 11 37 24 37 37 0 20 -5 20 -122 18 -114 -3 -123 -4 -123 -22 0
-14 12 -23 45 -34 25 -8 47 -16 49 -18 5 -4 -110 -344 -119 -349 -4 -3 -33 74
-65 171 -62 192 -62 187 3 200 25 5 32 11 32 30 l0 24 -139 0 c-133 0 -139 -1
-144 -21z"/>
					<path d="M10293 12290 c-37 -15 -28 -43 20 -65 l42 -20 5 -195 c4 -130 10
-204 19 -221 21 -41 68 -64 132 -62 64 1 85 10 139 59 l38 35 12 -37 c16 -47
61 -67 130 -58 36 5 51 12 55 25 8 26 0 36 -35 44 -57 13 -60 26 -60 279 l0
229 -96 -6 c-54 -4 -98 -8 -100 -9 -1 -2 -4 -14 -6 -27 -3 -21 3 -26 47 -41
l50 -17 0 -173 0 -173 -26 -20 c-38 -27 -112 -34 -145 -13 -47 32 -54 68 -54
281 l0 195 -72 -1 c-40 0 -83 -4 -95 -9z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
